import React from "react";
import ReactModal from "react-modal";
import { IconButton, css } from "theme-ui";
import { FaTimes } from "react-icons/fa";
import "./styles.css";

const styles = {
  modalContent: {
    borderRadius: `lg`,
    bg: `contentBg`,
    maxWidth: `container`,
    overflow: `hidden`,
    py: 5,
    px: 4,
  },
  close: {
    position: `absolute`,
    top: -5,
    right: [3, null, null, -5],
    size: `icon.md`,
    svg: {
      size: `icon.md`,
    },
    ":hover": {
      color: `omegaLighter`,
    },
  },
};

ReactModal.setAppElement("#___gatsby");

const Modal = ({
  children,
  contentStyles,
  activeModal,
  handleClose,
  ...props
}) => {
  return (
    <>
      <ReactModal
        isOpen={activeModal}
        closeTimeoutMS={300}
        onRequestClose={handleClose}
        className="ModalPortalContent"
        overlayClassName="ModalPortalOverlay"
        shouldFocusAfterRender={false}
        css={css({ ...styles.modalContent, ...contentStyles })}
        {...props}
      >
        <IconButton
          onClick={handleClose}
          sx={styles.close}
          aria-label="Close Modal"
          // eslint-disable-next-line jsx-a11y/no-autofocus
          autoFocus
        >
          <FaTimes />
        </IconButton>
        {children}
      </ReactModal>
    </>
  );
};

export default Modal;

import React from "react";
import { Container, Flex } from "theme-ui";
import StackTitle from "@solid-ui-components/StackTitle";
import FlexImage from "@solid-ui-components/FlexImage";
import FlexContent from "@solid-ui-components/FlexContent";
import FlexOverlapFade from "@solid-ui-components/FlexOverlapFade";
import ContentImages from "@solid-ui-components/ContentImages";
import ContentButtons from "@solid-ui-components/ContentButtons";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import Divider from "@solid-ui-components/Divider";

const styles = {
  items: {
    flexWrap: `wrap`,
    mx: [-2, -4],
    "& > div": {
      flex: 1,
      px: [2, 4],
      textAlign: [`center`, `unset`],
    },
  },
  textDesc: {
    // width: "200%",
    // marginLeft: "-200px",
  },
  seventyThirty: {

  }
};

const FeaturesWithPhotoBlock05 = ({
  content: {
    collection,
  },
  reverse,
  layout = 'fiftyFifty'
}) => (
  <Container sx={{ position: `relative` }}>
    {collection && (
      <>
        {collection.map(({ kicker, title, text_1, text_2, images, collection, buttons, }, index) => (

          <React.Fragment key={index}>
            <Flex
              sx={{
                alignItems: [null, `center`],
                flexDirection: [
                  reverse ? `column-reverse` : `column`,
                  reverse ? `row-reverse` : `row`,
                ],
                mx: [null, null, null, -4],
              }}
            >
              <FlexImage reverse={reverse}>
                <ContentImages content={{ images }} reverse={reverse} />
              </FlexImage>
              <FlexContent reverse={reverse}>
                <div style={styles.textDesc}>
                  <StackTitle
                    kicker={kicker}
                    title={title}
                    lines={[text_1, text_2]}
                    align={[`center`, `left`]}
                    space={0}
                  />
                </div>


                <ContentButtons content={buttons} space={3} />
              </FlexContent>
            </Flex>
            <Divider space="5" />
            <FlexOverlapFade direction={reverse ? "ltr" : "rtl"} />
          </ React.Fragment>
        ))}

      </>
    )}
  </Container>
);

export default WithDefaultContent(FeaturesWithPhotoBlock05);

import React from 'react'
import { Container, Flex, Box, Heading, css } from 'theme-ui'
import ContentText from '@solid-ui-components/ContentText'
import Reveal from '@solid-ui-components/Reveal'
import StackTitle from '@solid-ui-components/StackTitle'
import ContentButtons from '@solid-ui-components/ContentButtons'
import WithDefaultContent from '@solid-ui-blocks/WithDefaultContent'

const styles = {
  listItem: {
    alignItems: `stretch`,
    justifyContent: `flex-start`
  },
  line: {
    position: `absolute`,
    bottom: `10px`,
    left: `50%`,
    width: `1px`,
    height: t => `calc(100% - ${t.sizes.icon.md + 20}px)`,
    transform: `translateX(-50%)`,
    zIndex: 1,
    bg: `omega`
  },
  number: {
    color: `white`,
    fontWeight: `body`,
    borderRadius: `full`,
    bg: `alpha`,
    size: `icon.md`,
    textAlign: `center`,
    p: 2,
    mb: 0
  }
}

const FeaturesBlock03 = ({
  content: { kicker, title, text_1, text_2, buttons, collection }
}) => (
  <Container>
    <Flex sx={{ flexDirection: [`column`, null, `row`], m: [0, -4] }}>
      <Box
        sx={{
          flexBasis: [`1`, `1/2`],
          alignSelf: `center`,
          mx: 4,
          mb: [5, null, 0]
        }}
      >
        <Reveal effect='fadeInDown' threshold={0.7}>
          <StackTitle
            kicker={kicker}
            title={title}
            lines={[text_1, text_2]}
            align='left'
            space={0}
          />
          <ContentButtons content={buttons} space={3} />
        </Reveal>
      </Box>
      {collection && (
        <Box
          sx={{
            flexBasis: [`1`, `1/2`],
            alignSelf: `start`,
            mx: 4
          }}
        >
          <Flex sx={{ flexWrap: `wrap` }}>
            {collection.map(({ title, description }, index) => (
              <Reveal
                key={`item-${index}`}
                effect='fadeInDown'
                delay={0.2 * (index + 1)}
                duration={0.7}
                threshold={0.7}
                css={css({ flexBasis: [`1`, null, null, `1`] })}
              >
                <Flex sx={styles.listItem}>
                  <Box sx={{ position: `relative`, flexShrink: 0, mr: 4 }}>
                    <Heading variant='h4' as='div' sx={styles.number}>
                      {index + 1}
                    </Heading>
                    {index + 1 < collection.length && <Box sx={styles.line} />}
                  </Box>
                  <Box>
                    <ContentText content={title} variant='h5' mb='2' />
                    <ContentText
                      content={description}
                      sx={{ maxWidth: `90%` }}
                      mb='4'
                    />
                  </Box>
                </Flex>
              </Reveal>
            ))}
          </Flex>
        </Box>
      )}
    </Flex>
  </Container>
)

export default WithDefaultContent(FeaturesBlock03)

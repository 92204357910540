import React from "react";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { Container, Box, css } from "theme-ui";
// import Modal from "@solid-ui-components/Modal";
import AdvisorModal from "@solid-ui-components/AdvisorsModal";
import getImage from "@solid-ui-components/utils/getImage";
import Reveal from "@solid-ui-components/Reveal";
import ContentContainer from "@solid-ui-components/ContentContainer";
import ContentText from "@solid-ui-components/ContentText";

const styles = {
  avatar: {
    size: 140,
    display: `block`,
    bg: `transparent`,
    borderTopColor: `transparent`,
    borderTopWidth: `lg`,
    borderTopStyle: `solid`,
    borderRadius: "20px",
    boxSizing: `content-box`,
    mx: `auto`,
    mt: -90,
    mb: 3,
  },
  paragraph: {},
};
// content: { identifier, kicker, title, description, images, map, collection, form, avatar, buttons, }, id, reverse,

const ModalBlock02 = ({
  kicker,
  title,
  description,
  avatar,
  activeModal,
  handleClose,
  id,
}) => {
  return (
    <AdvisorModal
      activeModal={activeModal}
      handleClose={handleClose}
      contentStyles={{ maxWidth: "80%" }}
    >
      <Container sx={{ position: `relative` }}>
        <Box
          key={`item-${id}`}
          sx={{ flexBasis: [`1`, null, `1/2`, `1/3`], flexGrow: 1, p: 3 }}
        >
          <Reveal effect="fadeInLeft">
            <ContentContainer
              variant="cards.paper"
              sx={{ textAlign: `center` }}
            >
              <Img
                image={getImage(avatar?.src)}
                alt={avatar?.alt}
                objectPosition="top center"
                css={css(styles.avatar)}
              />
              <ContentText content={kicker} variant="h5" />
              <ContentText content={title} variant="h6" />
              <ContentText
                content={description}
                variant="p"
                sx={styles.paragraph}
              />
            </ContentContainer>
          </Reveal>
        </Box>
      </Container>
    </AdvisorModal>
  );
};

export default ModalBlock02;

import React from "react";
import { Container, Flex, Box, css } from "theme-ui";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import BoardOfAdvisor from "./BoardOfAdvisors/BoardOfAdvisors";

const BoardMembers = ({ content: { collection } }) => (
  <Container>
    <Flex sx={{ m: -3, flexWrap: `wrap` }}>
      {collection?.map(
        ({ container, avatar, kicker, title, description }, index) => (
          <Box
            key={`item-${index}`}
            sx={{ flexBasis: [`1`, null, `1/2`, `1/3`], flexGrow: 1, p: 3 }}
          >
            <BoardOfAdvisor
              container={container}
              avatar={avatar}
              kicker={kicker}
              title={title}
              description={description}
              index={index}
            />
          </Box>
        )
      )}
    </Flex>
  </Container>
);

export default WithDefaultContent(BoardMembers);

import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Container } from "theme-ui";
import Layout from "@solid-ui-layout/Layout";
import Seo from "@solid-ui-components/Seo";
import Divider from "@solid-ui-components/Divider";
import ModalWithTabs from "@solid-ui-blocks/Modal/Block02";
// import MemberDetails from "@solid-ui-blocks/Modal/BoardModal";
import Header from "@solid-ui-blocks/Header/Block01";
import Tabs from "@solid-ui-components/Tabs";
import Process from "@solid-ui-blocks/Features/Block03";
import FeatureOne from "@solid-ui-blocks/FeaturesWithPhoto/Block02";
import FeatureTwo from "@solid-ui-blocks/FeaturesWithPhoto/Block05";
import ListBlock from "@solid-ui-blocks/FeaturesWithPhoto/ListBlock";
import BoardMembers from "@solid-ui-blocks/Testimonials/BoardMembers";
import Strategies from "@solid-ui-blocks/Stats/Block01";
import Footer from "@solid-ui-blocks/Footer/Block01";
import { normalizeBlockContentNodes } from "@blocks-helpers";
import theme from "./_theme";
import { styles } from "./styles/_technology.styles";

const AboutUs = (props) => {
  let preSelectedLanguage = 'en';
  if (typeof window !== 'undefined') {
    preSelectedLanguage = localStorage.getItem("selectedLanguage");
  } 
  
  const { allBlockContent, site } = useStaticQuery(query);
  const [selectedlanguage, setSelectedlanguage] = useState(
    preSelectedLanguage
      ? preSelectedLanguage
      : site.siteMetadata.languages.defaultLangKey
  );

  const content = normalizeBlockContentNodes(allBlockContent?.nodes);

  function switchLanguage(lang) {
    setSelectedlanguage(lang);
  }

  if (typeof window !== 'undefined') {
    localStorage.setItem("selectedLanguage", selectedlanguage);
  }

  console.log("blockcontent about", content[`team-tab.${selectedlanguage}`]);
  console.log("content about", content);
  return (
    <Layout theme={theme} {...props}>
      <Seo title="Home" />
      {/* Modals */}
      <ModalWithTabs
        content={content[`authentication.${selectedlanguage}`]}
        reverse
      />
      <ModalWithTabs content={content[`contact.${selectedlanguage}`]} />

      {/* Blocks */}
      <Header
        switchLanguage={switchLanguage}
        languages={site.siteMetadata.languages.langs}
        content={content[`header.${selectedlanguage}`]}
      />

      <Divider space="5" />
      <Divider space="5" />

      <Container variant="wide" sx={styles.tabsContainer}>
        <Tabs space={5}>
          {/* About-us tab */}
          <div content={content[`section-one.${selectedlanguage}`]}>
            <FeatureOne content={content[`section-one.${selectedlanguage}`]} />
            <Divider space="2" />
            <FeatureTwo
              content={content[`section-two.${selectedlanguage}`]}
              reverse
            />
            <Divider space="5" />
            <Process content={content[`process.${selectedlanguage}`]} />
          </div>

          {/* Board of advisors */}
          <div content={content[`board-of-advisors.${selectedlanguage}`]}>
            <BoardMembers
              content={content[`board-of-advisors.${selectedlanguage}`]}
            />
          </div>

          {/* Our Partners */}
          <div content={content[`partner-tab-feature.${selectedlanguage}`]}>
            <ListBlock
              content={content[`partner-tab-feature.${selectedlanguage}`]}
            />
          </div>

          {/* Our Team */}
          <div content={content[`team-tab-feature.${selectedlanguage}`]}>
            <BoardMembers
              content={content[`team-tab-feature.${selectedlanguage}`]}
            />
          </div>
        </Tabs>
      </Container>

      <Divider space="5" />

      <Strategies content={content[`book-demo.${selectedlanguage}`]} />

      <Divider space="5" />
      <Divider space="5" />

      <Footer content={content[`footer.${selectedlanguage}`]} />
    </Layout>
  );
};

const query = graphql`
  query siteAboutUsBlockContent {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
    allBlockContent(
      filter: {
        page: {
          in: [
            "site/about-us/section-one"
            "site/about-us/section-two"
            "site/about-us/process"
            "site/about-us/partners"
            "site/about-us/team"
            "site/about-us/board"
            "site/shared/header"
            "site/shared/footer"
            "site/shared/contact"
            "site/shared/authentication"
            "site/shared/book-demo"
          ]
        }
      }
    ) {
      nodes {
        ...BlockContent
      }
    }
  }
`;
export default AboutUs;

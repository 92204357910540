import React, { useState } from "react";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { Container, css } from "theme-ui";
import ContentText from "@solid-ui-components/ContentText";
import ContentContainer from "@solid-ui-components/ContentContainer";
import Reveal from "@solid-ui-components/Reveal";
import WithDefaultContent from "@solid-ui-blocks/WithDefaultContent";
import getImage from "@solid-ui-components/utils/getImage";
// import ContentButtons from "@solid-ui-components/ContentButtons";
import MemberDetails from "@solid-ui-blocks/Modal/BoardModal";

const styles = {
  avatar: {
    size: 140,
    display: `block`,
    bg: `transparent`,
    borderTopColor: `transparent`,
    borderTopWidth: `lg`,
    borderTopStyle: `solid`,
    borderRadius: "20px",
    boxSizing: `content-box`,
    mx: `auto`,
    mt: -90,
    mb: 3,
  },
  paragraph: {
    display: "-webkit-box",
    WebkitLineClamp: 6,
    WebkitBoxOrient: "vertical",
    maxHeight: "200px",
    overflow: "hidden",
  },
  button: {
    backgroundColor: "transparent",
    border: "none",
    cursor: "pointer",
    fontFamily: "Fira Sans",
    fontWeight: "600",
  },
};

const BoardOfAdvisors = ({
  container,
  avatar,
  kicker,
  title,
  description,
  index,
}) => {
  const [activeModal, setActiveModal] = useState(false);

  const setMemberDetails = () => {
    setActiveModal(true);
  };

  return (
    <Container>
      <Reveal effect="fadeInLeft" delay={0.25 * (index + 2)}>
        <ContentContainer
          content={container}
          variant="cards.paper"
          sx={{ textAlign: `center` }}
        >
          <Img
            image={getImage(avatar?.src)}
            alt={avatar?.alt}
            objectPosition="top center"
            css={css(styles.avatar)}
          />
          <ContentText content={kicker} variant="h5" />
          <ContentText content={title} variant="h6" />
          <ContentText
            content={description}
            variant="p"
            sx={styles.paragraph}
          />

          {/* <ContentButtons onClick={setMemberDetails} content={buttons} space={0} /> */}
          <button style={styles.button} onClick={setMemberDetails}>
            Learn More
          </button>
        </ContentContainer>
      </Reveal>

      <MemberDetails
        kicker={kicker}
        title={title}
        description={description}
        avatar={avatar}
        activeModal={activeModal}
        handleClose={() => setActiveModal(null)}
      />
    </Container>
  );
};

export default WithDefaultContent(BoardOfAdvisors);
